* {
  background: #060b1a;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
}

:root {
  --primary-color: #379cf6;
  --btn-primary-bg: #379cf6;
  --btn-secondary-bg: #090238;
  --small-text-color: rgba(255, 255, 255, 0.774);
}

.App {
  text-align: center;
  height: 90vh;
  padding-top: 25px;
  padding-bottom: 50px;
}

.App a {
  text-decoration: none;
  color: white;
}

.bild {
  width: auto;
  height: auto;
  position: relative;
  z-index: 1;
}


.bild a {
  text-decoration: none;
}

.bild img {
  height: 250px;
  width: 250px;
}

.bild h1 {
  color: white;
}

.hero__btns {
  display: inline-block;
  align-items: center;
  flex-direction: column;
  padding-left: 10px;
}

.primary__btn {
  padding: 0.8rem 1.5rem;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-top: 15px;
  background: #090238;
  border: 1px solid #379cf6;
}

.buttons {
  padding-top: 25px;
  color: #379cf6;
}

.buttons1 {
  padding-top: 25px;
  color: #379cf6;
  padding-bottom: 50px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #060b1a;
  color: white;
  text-align: center;
}

.text {
  color: white;
  word-break: break-word;
  padding-top: 50px;
  width: 50%;
  display: inline-block;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .text {
    padding-top: 55px;
  }

  .App {
    height: 89vh;
  }
  
}

@media screen and (max-width: 400px) {
  .text {
    padding-top: 50px;
  }

  .App {
    height: 85vh;
  }
  
}

@media screen and (max-width: 361px) {
  .text {
    padding-top: 25px;
  }

  .App {
    height: 85vh;
  }
  
}

@media screen and (min-width: 1024px) {
  .text {
    padding-top: 75px;
  }

  .App {
    height: 85vh;
  }
  
}